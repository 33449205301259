#dowloadAppModal {
    .modal-body {
        background-color: hsl(220deg 20% 97%);
    }
    .dowload-app-area {
        .app-link-img {
            width: 30%;
        }
    }
}
.normal-font-w {
    font-weight: normal;
}

/* responsive */

@media only screen and (max-width: 767px) {
  /* For mobile phones: */
    #dowloadAppModal {
        .dowload-app-area {
            a {
                display: inline-block;
            }
            .app-link-img {
                width: 40%;
            }
        }
    }
}

@media only screen and (max-width: 575px) {
  /* For mobile phones: */
    #dowloadAppModal {
        .dowload-app-area {
            .app-link-img {
                width: 50%;
            }
        }
    }
}
